<template>
  <b-card-code title="" no-body>
    <b-card-body>
      <h3>Total Notifikasi Admin : {{ rows }}</h3>
    </b-card-body>

    <b-card-body>
      <b-row>
        <b-col md="2" xl="2" class="mb-1">
          <!-- status forum -->
          <b-form-group label="Message Notification" label-for="message">
            <validation-provider #default="{ errors }" name="notif_message">
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-input
                  id="basicInput"
                  placeholder="search message notifikasi"
                  v-model="filter.admnotif_msg"
                />
              </b-input-group>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="2" xl="2" class="mb-1">
          <!-- status admin -->
          <b-form-group label="Name notification" label-for="table_notif">
            <validation-provider #default="{ errors }" name="table_notif">
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-input
                  id="basicInput"
                  placeholder="search name notifikasi"
                  v-model="filter.admnotif_table_name"
                />
              </b-input-group>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col md="2" xl="2" class="mb-1">
          <!-- status admin -->
          <b-form-group
            label="Table Id Notification"
            label-for="table_id_notification"
          >
            <validation-provider
              #default="{ errors }"
              name="table_id_notification"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-input
                  id="basicInput"
                  placeholder="search name notifikasi"
                  v-model="filter.admnotif_table_id"
                />
              </b-input-group>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col md="2" xl="2" class="mb-1">
          <!-- status admin -->
          <b-form-group label="Tanggal Notification" label-for="tanggal">
            <validation-provider #default="{ errors }" name="tanggal">
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-input
                  id="tanggal"
                  placeholder="search name notifikasi"
                  v-model="filter.created_at"
                  type="date"
                />
              </b-input-group>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col lg="2" md="2" class="mb-50">
          <b-button
            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
            variant="outline-primary"
            class="mt-0 mt-md-2"
            @click="refreshPage()"
          >
            <feather-icon icon="SearchIcon" class="mr-25" />
            <span>Search</span>
          </b-button>
        </b-col>
      </b-row>

      <b-button-toolbar
        class="d-flex justify-content-between flex-wrap pt-0"
        aria-label="Toolbar with button groups and dropdown menu"
      >
        <!-- group -->
        <b-button-group>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            @click="reloadPage"
          >
            <feather-icon icon="RefreshCwIcon" />
          </b-button>
        </b-button-group>
      </b-button-toolbar>
    </b-card-body>

    <!-- table -->
    <b-table
      ref="selectableTable"
      selectable
      :select-mode="selectMode"
      :items="items"
      :fields="fields"
      responsive
      class="mb-0"
      @row-selected="onRowSelected"
    >
      <template #cell(document)="data">
        <a :href="data.item.document" v-if="data.item.document" target="_blank"
          >Open</a
        >
      </template>
      <template #cell(status)="data">
        <b-badge :variant="status[1][data.value]">
          {{ status[0][data.value] }}
        </b-badge>
      </template>
      <template #cell(action)="data">
        <b-dropdown variant="link" no-caret toggle-class="p-0" right>
          <template #button-content>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="btn-icon"
            >
              <feather-icon icon="SettingsIcon" />
            </b-button>
          </template>

          <b-dropdown-item @click="redirectToNotification(data.item)">
            <feather-icon icon="SettingsIcon" size="16" />
            <span class="align-middle ml-50">Detail</span>
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
      <div></div>
      <!--pagination -->
      <div class="demo-inline-spacing pt-0">
        <b-pagination
          v-model="currentPage"
          hide-goto-end-buttons
          :total-rows="rows"
          :per-page="perPage"
        />
      </div>
    </b-card-body>
    <b-modal id="modal-export" hide-footer>
      <template #modal-title> Input Email Tujuan </template>
      <div class="d-block text-center">
        <p>
          Data yang akan diexport lebih dari <b>500</b> baris dan akan dikirim
          via email, Silahkan masukkan email anda!
        </p>
      </div>
      <b-form-group>
        <label for="email">Email:</label>
        <b-form-input
          id="email"
          type="email"
          placeholder="Email Address"
          v-model="email"
        />
      </b-form-group>
      <b-button
        class="mt-3"
        variant="outline-primary"
        block
        @click="exportData()"
      >
        <feather-icon icon="DownloadCloudIcon" /> Export
      </b-button>
    </b-modal>
  </b-card-code>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";

import {
  BTable,
  BButton,
  BFormGroup,
  BAvatar,
  BBadge,
  BCardBody,
  BPagination,
  BFormTextarea,
  BFormFile,
  BButtonToolbar,
  BButtonGroup,
  BDropdown,
  BDropdownItem,
  BFormInput,
  BRow,
  BCol,
  BModal,
} from "bootstrap-vue";
import API from "@/api";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BCardCode,
    BTable,
    BButton,
    BAvatar,
    BCardBody,
    BBadge,
    vSelect,
    BPagination,
    BButtonToolbar,
    BButtonGroup,
    BButton,
    BFormFile,
    BDropdown,
    BDropdownItem,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BModal,
    ValidationProvider,
    ValidationObserver,
    BFormTextarea,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      currentPage: 1,
      rows: 0,
      perPage: 20,
      modes: ["multi", "single", "range"],
      fields: [
        { key: "id", label: "Id" },
        [{ key: "admnotif_msg", label: "Message Notification" }],
        [{ key: "admnotif_table_name", label: "Table Name" }],
        [{ key: "admnotif_table_id", label: "Table ID" }],
        [{ key: "created_at", label: "Tanggal" }],
        "action",
      ],
      /* eslint-disable global-require */
      items: [],
      /* eslint-disable global-require */
      status: [
        {
          0: "Register",
          1: "Verify",
          2: "Can't find",
          3: "Input wrong data",
        },
        {
          0: "light-primary",
          1: "light-success",
          2: "light-warning",
          3: "light-warning",
          5: "light-info",
        },
      ],
      selectMode: "multi",
      selected: [],

      search: null,
      detailPendidikan: {},
      updateGaleri: {},
      base64Data: "",
      option: ["video", "image"],
      filter: {
        admnotif_msg: null,
        admnotif_table_name: null,
        admnotif_table_id: null,
        created_at: null,
      },
    };
  },
  methods: {
    onRowSelected(items) {
      this.selected = items;
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected();
    },
    refreshPage() {
      if (this.currentPage == 1) {
        this.getNotifikasiAdmin();
      } else {
        this.currentPage = 1;
      }
    },
    formatNames(files) {
      if (files.length === 1) {
        return files[0].name;
      }
      return `${files.length} files selected`;
    },
    reloadPage() {
      location.reload();
    },

    redirectToNotification(item) {
      switch (item.admnotif_table_name) {
        case "anggota":
          this.$router.push(`/anggota/${item.admnotif_table_id}`);
          break;
        case "reksips":
          this.$router.push(
            `/rekomendasi_izin_praktik/${item.admnotif_table_id}`
          );
          break;
        case "strs":
          this.$router.push(`/str/${item.admnotif_table_id}`);
          break;
        case "pends":
          this.$router.push(`/pendidikan/${item.admnotif_table_id}`);
          break;
        case "invoices":
          this.$router.push(`/invoice/${item.admnotif_table_id}`);
          break;
        case "kompetensis":
          this.$router.push(`/kompentensi/${item.admnotif_table_id}`);
          break;
        case "krips":
          this.$router.push(`/krip/${item.admnotif_table_id}`);
          break;
        case "contacts":
          this.$router.push(`/contact/${item.admnotif_table_id}`);
          break;
        default:
          this.$router.push("/");
          break;
      }
    },

    async getNotifikasiAdmin() {
      try {
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", true);

        const form = {
          limit: this.perPage,
          page: this.currentPage,
          search: this.search,
          ...this.filter, //do filter(search) notification
        };
        const { data } = await API.notifikasi_admin.list(form);
        this.data_table = data;
        this.items = data.data;
        this.rows = data.total;

        this.handleMsgSuccess("Berhasil tampilkan data notifikasi");
      } catch (error) {
        console.log(error);
        this.handleMsgErrors(error, "Terjadi kesalahan silahkan coba lagi!");
      } finally {
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", false);
      }
    },
  },
  mounted() {
    this.getNotifikasiAdmin();
  },
  watch: {
    currentPage() {
      this.getNotifikasiAdmin();
    },
  },
};
</script>

<style lang="scss">
.b-table-selectable {
  .feather {
    font-size: 1.3rem;
  }
}
</style>
